<template>
  <div class="warehouse-manage">
    <div class="main">
      <div class="list">
        <div class="list-item"
             v-for="item in list"
             :key="item.id"
             @click="itemClick(item.id)">
          <div class="content">
            <div class="name">
              {{ item.name }}
            </div>
            <div class="action"
                 @click.stop="del(item.id)">
              <van-icon name="cross" />
            </div>
          </div>
        </div>
      </div>
      <van-popup class="dialog"
                 v-model="showDialog"
                 :style="{ width: '95%' }">
        <div style="padding:12px;">
          <van-form @submit="onSubmit">
            <van-field label="仓库名称"
                       v-model="form.name"
                       placeholder="请输入"
                       required
                       :rules="[{ required: true, message: '仓库不能为空' }]"></van-field>
            <van-field label="仓库编码"
                       v-model="form.code"
                       placeholder="请输入"
                       required
                       :rules="[{ required: true, message: '仓库编码不能为空' }]"></van-field>
            <van-field label="所属科室"
                       v-model="form.type"
                       placeholder="请选择"
                       required
                       readonly
                       clickable
                       @click="showWarehouseType = true"
                       :rules="[{ required: true, message: '所属科室不能为空' }]"></van-field>
            <van-field label="仓管"
                       v-model="adminComputed"
                       placeholder="请选择"
                       readonly
                       clickable
                       @click="adminClick"></van-field>
            <van-field label="可使用单位"
                       v-model="orgComputed"
                       placeholder="请选择"
                       readonly
                       clickable
                       @click="authClick"></van-field>
            <van-field label="仓库地址"
                       v-model="form.address"
                       placeholder="请输入"></van-field>
            <van-field label="审批流程步骤"
                       v-model="form.stepId"
                       placeholder="请选择流程名称"
                       readonly
                       clickable
                       required
                       :rules="[{ required: true, message: '审批流程步骤不能为空' }]"
                       @click="showStepSelect=true"></van-field>
            <van-field label="仓库状态"
                       v-model="statusComputed"
                       placeholder="请选择"
                       readonly
                       clickable
                       @click="statusClick"></van-field>
            <div class="action">
              <van-button class="btn"
                          type="info"
                          round
                          size="small"
                          native-type="button"
                          v-if="form.id"
                          @click="editStockSite">编辑货位</van-button>
              <van-button class="btn"
                          type="info"
                          round
                          size="small">确定</van-button>
              <van-button class="btn"
                          type="default"
                          round
                          size="small"
                          native-type="button"
                          @click="dialogCancel">取消</van-button>
            </div>
          </van-form>
        </div>
      </van-popup>
      <van-empty description="暂无数据"
                 v-if="!list.length" />

      <van-popup v-model="showWarehouseType"
                 round
                 position="bottom">
        <custom-popup title="所属科室"
                      value-key="value"
                      :columns="warehouseTypeColumns"
                      v-if="warehouseTypeColumns.length"
                      @cancel="showWarehouseType = false"
                      @confirm="warehouseTypeConfirm"></custom-popup>
        <van-empty description="暂无数据"
                   v-if="!warehouseTypeColumns.length" />
      </van-popup>

      <!-- 仓库列表 -->
      <van-popup v-model="showStepSelect"
                 round
                 position="bottom">
        <custom-popup title="选择流程步骤"
                      value-key="value"
                      :columns="flowStepList"
                      v-if="flowStepList.length"
                      @cancel="showStepSelect = false"
                      @confirm="handleFlowStepChange"></custom-popup>
        <van-empty description="暂无数据"
                   v-if="!flowStepList.length" />
      </van-popup>

      <van-popup v-model="showAdmin"
                 round
                 position="bottom"
                 class="popup-wrap">
        <div class="popup-title">仓管</div>
        <van-cell clickable
                  title="全选">
          <template #right-icon>
            <van-checkbox v-model="isAll"
                          shape="square"
                          @click="toggle(!isAll,'keepersCheckboxGroup')"></van-checkbox>
          </template>
        </van-cell>
        <div class="popup-main">

          <van-checkbox-group v-model="form.keepers"
                              ref="keepersCheckboxGroup"
                              v-if="warehouseAdminColumns.length">
            <van-list finished-text="没有更多了">

              <van-cell clickable
                        v-for="option in warehouseAdminColumns"
                        :key="option.id"
                        :title="option.fullName">
                <template #right-icon>
                  <van-checkbox shape="square"
                                :name="option.id"
                                ref="checkboxes" />
                </template>
              </van-cell>
            </van-list>
          </van-checkbox-group>
          <van-empty description="暂无数据"
                     v-if="!warehouseAdminColumns.length" />
        </div>
        <div class="foot-btn">
          <van-button type="default"
                      class="button"
                      color="#A6A9AD"
                      round
                      size="small"
                      @click="showAdmin = false">取消</van-button>
          <van-button type="info"
                      round
                      class="button"
                      size="small"
                      @click="warehouseAdminConfirm">确定</van-button>
        </div>
      </van-popup>

      <van-popup v-model="showOrgs"
                 round
                 class="popup-wrap"
                 position="bottom">
        <div class="popup-title">可使用单位</div>
        <van-cell clickable
                  title="全选">
          <template #right-icon>
            <van-checkbox v-model="isAll"
                          shape="square"
                          @click="toggle(!isAll,'orgCheckboxGroup')"></van-checkbox>
          </template>
        </van-cell>
        <div class="popup-main">

          <van-checkbox-group v-model="form.org"
                              ref="orgCheckboxGroup"
                              v-if="organizationColumns.length">
            <van-list finished-text="没有更多了">

              <van-cell clickable
                        v-for="option in organizationColumns"
                        :key="option.id"
                        :title="option.name">
                <template #right-icon>
                  <van-checkbox shape="square"
                                :name="option.id"
                                ref="checkboxes" />
                </template>
              </van-cell>
            </van-list>
          </van-checkbox-group>
          <van-empty description="暂无数据"
                     v-if="!organizationColumns.length" />
        </div>
        <div class="foot-btn">
          <van-button type="default"
                      class="button"
                      color="#A6A9AD"
                      round
                      size="small"
                      @click="showOrgs = false">取消</van-button>
          <van-button type="info"
                      round
                      class="button"
                      size="small"
                      @click="orgsConfirm">确定</van-button>
        </div>
      </van-popup>
      <van-popup v-model="showStatus"
                 round
                 position="bottom">

        <custom-popup title="状态"
                      value-key="label"
                      :columns="statusColumns"
                      @cancel="showStatus = false"
                      @confirm="statusConfirm"
                      v-if="statusColumns.length"></custom-popup>
        <van-empty description="暂无数据"
                   v-if="!statusColumns.length" />
      </van-popup>
    </div>
    <div class="footer">
      <van-button class="add-btn"
                  type="info"
                  round
                  @click="add">新增仓库</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/warehouse/warehouseManage.less";
import { SUCCESS_CODE } from "@/apis/notification";
import { assign, cloneDeep, findIndex } from "lodash-es";
export default {
  name: "WarehouseManage",
  data() {
    return {
      form: {
        name: "",
        code: "",
        type: "",
        stepId: "",
        keepers: [],
        org: [],
        address: "",
        status: "ENABLE"
      },
      flowStepList: [],
      showStepSelect: false,
      warehouseTypeColumns: [],
      showWarehouseType: false,
      warehouseAdminColumns: [],
      showAdmin: false,
      organizationColumns: [],
      showOrgs: false,
      list: [],
      showDialog: false,
      statusColumns: [
        { label: "启用", value: "ENABLE" },
        { label: "禁用", value: "DISABLE" },
        { label: "删除", value: "DELETED" }
      ],
      showStatus: false,
      isAll: false
    };
  },
  computed: {
    warehouseType() {
      let item = this.statusColumns.find(item => item.value == this.form.status);
      return item ? item.label : "";
    },
    adminComputed() {
      let keepers = this.form.keepers.reduce((prev, cur) => {
        let item = this.warehouseAdminColumns.find(item => item.id == cur);
        if (!item) return prev;
        return prev.concat(item.fullName);
      }, []);
      if (keepers.length !== 0 && keepers.length === this.warehouseAdminColumns.length) {
        this.isAll = true;
        this.$refs.keepersCheckboxGroup.toggleAll(true);
      } else {
        this.isAll = false;
      }
      return keepers.join(",");
    },
    orgComputed() {
      let org = this.form.org.reduce((prev, cur) => {
        let item = this.organizationColumns.find(item => item.id == cur);
        return prev.concat(item.name);
      }, []);
      if (org.length !== 0 && org.length === this.organizationColumns.length) {
        this.isAll = true;
        this.$refs.orgCheckboxGroup.toggleAll(true);
      } else {
        this.isAll = false;
      }
      return org.join(",");
    },
    statusComputed() {
      let item = this.statusColumns.find(item => item.value == this.form.status);
      return item ? item.label : "";
    }
  },
  methods: {
    async getWarehouseList() {
      let params = {
        useType: "WAREHOUSE_MANAGER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.list = ret.data;
    },
    getWarehouseDetail(id) {
      return this.$api.getWarehouseDetail({
        placeholder: {
          id
        }
      });
    },
    async getWarehouseTypeList() {
      // let query = "name[sin]所属科室";
      let ret = await this.$api.getDictsList({
        params: {
          // query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseTypeColumns = ret.data["所属科室"][0].dictOptions;

      this.flowStepList = ret.data["物品审批流程"][0].dictOptions;
    },

    async getAdminList() {
      let params = {
        clientName: "ms-stock",
        clientRoleName: "MS_STOCK_KEEPER"
      };
      let ret = await this.$api.getAdminList({
        params
      });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseAdminColumns = ret.data;
    },
    async getOrganizationList() {
      let ret = await this.$api.getOrganizationList();
      if (ret.code != SUCCESS_CODE) return;
      this.organizationColumns = ret.data;
    },
    getWarehouseKeepers(warehouseId) {
      return this.$api.getWarehouseKeepers({
        placeholder: {
          id: warehouseId
        }
      });
    },
    getWarehouseOrgs(warehouseId) {
      return this.$api.getWarehouseOrgs({
        placeholder: {
          id: warehouseId
        }
      });
    },
    deleteWarehouse(id) {
      let params = {
        ids: [id].join(",")
      };
      return this.$api.deleteWarehouse({ params });
    },
    addWarehouse() {
      let params = {
        name: this.form.name,
        code: this.form.code,
        type: this.form.type,
        address: this.form.address,
        status: this.form.status
        // keepers: this.form.keepers.map(item => ({ warehouseKeeperId: item }))
      };
      return this.$api.addWarehouse({ params });
    },
    setWarehouse() {
      let params = {
        name: this.form.name,
        code: this.form.code,
        type: this.form.type,
        address: this.form.address,
        status: this.form.status,
        stepId: this.form.stepId,
        keepers: this.form.keepers
          .filter(item => {
            let idx = findIndex(this.warehouseAdminColumns, { id: item });
            if (idx == -1) return false;
            return true;
          })
          .map(item => {
            let idx = findIndex(this.warehouseAdminColumns, { id: item });
            let warehouseKeeperName = this.warehouseAdminColumns[idx].fullName;
            return {
              warehouseKeeperId: item,
              warehouseKeeperName
            };
          })
      };
      let placeholder = {
        id: this.form.id
      };
      return this.$api.setWarehouse({ params, placeholder });
    },
    deleteWarehouseKeeper(warehouseId) {
      return this.$api.deleteWarehouseKeeper({
        placeholder: {
          id: warehouseId
        }
      });
    },
    addWarehouseKeeper(warehouseId, warehouseKeeprs) {
      return this.$api.addWarehouseKeeper({
        params: warehouseKeeprs,
        placeholder: {
          id: warehouseId
        }
      });
    },
    deleteWarehouseOrg(warehouseId) {
      return this.$api.deleteWarehouseOrg({
        placeholder: {
          id: warehouseId
        }
      });
    },
    addWarehouseOrg(warehouseId, orgs) {
      return this.$api.addWarehouseOrg({
        params: orgs,
        placeholder: {
          id: warehouseId
        }
      });
    },
    itemClick(id) {
      this.showDialog = true;
      this.warehouseId = id;
      this.getWarehouseDetail(id).then(ret => {
        if (ret.code != SUCCESS_CODE) return;
        assign(this.form, ret.data);
        this.form.id = ret.data.id;
      });
      this.getWarehouseKeepers(id).then(ret => {
        if (ret.code != SUCCESS_CODE) return;
        this.form.keepers = ret.data.map(item => item.warehouseKeeperId);
        this.form.keeperCopy = this.form.keepers.slice();
      });
      this.getWarehouseOrgs(id).then(ret => {
        if (ret.code != SUCCESS_CODE) return;
        this.form.org = ret.data.map(item => item.organizationId);
        this.form.orgCopy = this.form.org.slice();
      });
    },
    async onSubmit() {
      if (this.form.id) {
        // 编辑
        let ret = await this.setWarehouse();
        if (ret.code != SUCCESS_CODE) return;
        this.deleteWarehouseKeeper(this.form.id).then(ret => {
          if (ret.code != SUCCESS_CODE) return;
          let warehouseKeepers = this.form.keepers.map(id => {
            let idx = findIndex(this.warehouseAdminColumns, { id: id });
            return {
              warehouseKeeperId: id,
              warehouseKeeperName: this.warehouseAdminColumns[idx].fullName
            };
          });
          this.addWarehouseKeeper(this.form.id, warehouseKeepers);
        });
        this.deleteWarehouseOrg(this.form.id).then(ret => {
          if (ret.code != SUCCESS_CODE) return;
          let warehouseOrgId = this.form.org.map(id => {
            let idx = findIndex(this.organizationColumns, { id: id });
            return {
              organizationId: id,
              organizationName: this.organizationColumns[idx].name
            };
          });
          this.addWarehouseOrg(this.form.id, warehouseOrgId);
        });
        this.showDialog = false;
      } else {
        // 新增
        let ret = await this.addWarehouse();
        if (ret.code != SUCCESS_CODE) return;
        if (this.form.keepers.length) {
          let warehouseId = ret.data.id;
          let warehouseKeeperId = this.form.keepers.map(id => {
            let idx = findIndex(this.warehouseAdminColumns, { id: id });
            return {
              warehouseKeeperId: id,
              warehouseKeeperName: this.warehouseAdminColumns[idx].fullName
            };
          });
          this.addWarehouseKeeper(warehouseId, warehouseKeeperId);
        }
        if (this.form.org.length) {
          let warehouseId = ret.data.id;
          let warehouseOrgId = this.form.org.map(id => {
            let idx = findIndex(this.organizationColumns, { id: id });
            return {
              organizationId: id,
              organizationName: this.organizationColumns[idx].name
            };
          });
          this.addWarehouseOrg(warehouseId, warehouseOrgId);
        }
        this.showDialog = false;
        this.form = cloneDeep(this.formCopy);
        this.getWarehouseList();
      }
    },
    add() {
      this.form = cloneDeep(this.formCopy);
      this.showDialog = true;
    },
    del(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let ret = await this.deleteWarehouse(id);
          if (ret.code != SUCCESS_CODE) return;
          this.getWarehouseList();
        })
        .catch(() => { });
    },
    editStockSite() {
      if (!this.form.id) return this.$utils.warning("请选择货位仓库ID");
      this.$router.push({
        name: "warehouseStockSiteManage",
        params: {
          id: this.form.id
        }
      });
    },
    dialogCancel() {
      this.showDialog = false;
      this.form = cloneDeep(this.formCopy);
    },
    adminClick() {
      this.isAll = false;
      this.showAdmin = true;
      this.$nextTick(() => {
        this.$refs.keepersCheckboxGroup.toggleAll(false);
      });
    },
    authClick() {
      this.isAll = false;
      this.showOrgs = true;
      this.$nextTick(() => {
        this.$refs.orgCheckboxGroup.toggleAll(false);
      });
    },
    statusClick() {
      this.showStatus = true;
    },
    warehouseTypeConfirm(arg) {
      this.form.type = arg.value;
      this.showWarehouseType = false;
    },
    warehouseAdminConfirm(arg) {
      this.showAdmin = false;
    },
    orgsConfirm() {
      this.showOrgs = false;
    },
    statusConfirm(arg) {
      this.form.status = arg.value;
      this.showStatus = false;
    },
    toggle(isFlag, key) {
      this.$refs[key].toggleAll(this.isAll);
    },
    handleFlowStepChange(arg) {
      this.form.stepId = arg.value;
      this.showStepSelect = false;
    }
  },
  created() {
    this.formCopy = cloneDeep(this.form);
    this.getAdminList();
    this.getOrganizationList();
    this.getWarehouseList();
    this.getWarehouseTypeList();
  }
};
</script>

<style></style>
